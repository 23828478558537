<template>
 <div>
  <sdPageHeader title="Gebruiker">
  </sdPageHeader>
  <Main v-if="errorUser">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/user')">
       Naar gebruikers
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-else>
   <sdModal
    :onCancel="handlePasswordResetModalCancel"
    :visible="showNewPasswordModal"
    title="Wachtwoord reset"
    type="primary"
   >
    <ModalMain>
     <div class="create-account-form">
      <a-row :gutter="30">
       <a-col
        :xs="24"
       >
        Nieuw wachtwoord voor de gebruiker: <strong>{{ newPassword }}</strong>
       </a-col>
      </a-row>
     </div>
    </ModalMain>
   </sdModal>
   <a-row :gutter="15" type="flex">
    <a-col :lg="18" :md="16" :sm="14" :xs="24" style="display: flex;flex-flow: column;">
     <Upload>
      <div class="user-info-form">
       <BasicFormWrapper class="mb-25">
        <sdCards title="Persoonsgegevens">
         <a-skeleton v-if="loadingUser" active/>
         <a-form v-if="!loadingUser" :model="form" :rules="rules1" layout="vertical" @finish="UpdateUser1">
          <a-row :gutter="30">
           <a-col :sm="24" :xs="24" class="mb-25">
            <figure class="photo-upload align-center-v">
             <img
              v-if="form.imageFileURL && form.imageFileURL !== ''"
              :src="form.imageFileURL"
              alt=""
              style="max-width: 120px"
             />
             <img
              v-else
              :src="require('@/static/img/avatar/profileImage.png')"
              alt=""
              style="max-width: 120px"
             />
             <figcaption>
              <a-upload
               :action="`${fileUrl}users/${form.id}/file`"
               :disabled="!$can('update', 'user')"
               :headers="fileUploadHeaders"
               :multiple="false"
               name="file"
               @change="handleUpload">
               <a class="btn-upload" to="#">
                <sdFeatherIcons size="16" type="camera"/>
               </a>
              </a-upload>
             </figcaption>
            </figure>
           </a-col>
           <a-col :lg="9" :xs="24" class="mb-25">
            <a-form-item label="Voornaam" name="firstName">
             <a-input v-model:value="form.firstName" :disabled="!$can('update', 'user')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :lg="6" :xs="24" class="mb-25">
            <a-form-item label="Tussenvoegsel" name="middleName">
             <a-input v-model:value="form.middleName" :disabled="!$can('update', 'user')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :lg="9" :xs="24" class="mb-25">
            <a-form-item label="Achternaam" name="lastName">
             <a-input v-model:value="form.lastName" :disabled="!$can('update', 'user')" placeholder=""/>
            </a-form-item>
           </a-col>
          </a-row>
          <a-row :gutter="30">
           <a-col :lg="12" :xs="24" class="mb-25">
            <a-form-item label="Gebruikersnaam" name="username">
             <a-input v-model:value="form.username" disabled placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :lg="12" :xs="24" class="mb-25">
            <a-form-item label="E-mailadres" name="email">
             <a-input v-model:value="form.email" :disabled="!$can('update', 'user')" placeholder=""/>
            </a-form-item>
           </a-col>
          </a-row>
          <a-row>
           <a-col :lg="12" :xs="24">
            <div class="sDash_form-action">
             <sdButton :disabled="loadingPatchUser" :loading="loadingPatchUser && (clickedButton === 1)"
                       htmlType="Save" size="default" type="primary">
              Opslaan
             </sdButton>
            </div>
           </a-col>
          </a-row>
         </a-form>
        </sdCards>
       </BasicFormWrapper>
      </div>
     </Upload>
    </a-col>
    <a-col :lg="6" :md="8" :sm="10" :xs="24" style="display: flex;flex-flow: column;">
     <sdCards :style="!$can('update', 'user')?'flex: 1 1 auto;':''" title="Informatie">
      <a-skeleton v-if="loadingUser" active/>
      <a-row v-else :gutter="30">
       <a-col :xs="24" class="mb-25">
        <InfoWrapper>
         <div class="info-title">Status</div>
         <div class="info-text">{{
           !form.status || form.status === 'PENDING' ? 'Pending' : ''
          }}{{
           form.status && form.status === 'ACTIVE' ? 'Actief' : ''
          }}{{ form.status && form.status === 'INACTIVE' ? 'Inactief' : '' }}
         </div>
        </InfoWrapper>
        <InfoWrapper>
         <div class="info-title">Tweefactorauthenticatie</div>
         <div class="info-text">{{ form.hasTFA ? 'Actief' : 'Inactief' }}</div>
        </InfoWrapper>
       </a-col>
      </a-row>
     </sdCards>
     <sdCards v-if="$can('update', 'user')" style="flex: 1 1 auto;" title="Acties's">
      <a-skeleton v-if="loadingUser" active/>
      <a-row v-else :gutter="30">
       <a-col v-if="form.status && form.status !== 'PENDING'" :xs="24" class="mb-25">
        <div class="sDash_form-action">
         <sdButton :type="form.status !== 'INACTIVE' ? 'warning' : 'primary'" class="btn-signin w-100"
                   @click="updateUserStatus(form.status)">
          <sdFeatherIcons v-if="form.status === 'ACTIVE'" class="mr-2" size="16" type="x-circle"/>
          <sdFeatherIcons v-if="form.status === 'INACTIVE'" class="mr-2" size="16" type="check-circle"/>
          {{ form.status === 'ACTIVE' ? 'Deactiveren' : 'Activeren' }}
         </sdButton>
        </div>
       </a-col>
       <a-col v-if="form.status" :xs="24" class="mb-25">
        <div class="sDash_form-action">
         <a-popconfirm
          cancel-text="Nee"
          ok-text="Ja"
          placement="leftTop"
          title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
          @confirm="resetPassword"
         >
          <sdButton class="btn-icon w-100" to="#" type="primary">
           <sdFeatherIcons class="mr-2" size="16" type="repeat"/>
           Wachtwoord resetten
          </sdButton>
         </a-popconfirm>
        </div>
       </a-col>
       <a-col v-if="form.hasTFA" :xs="24" class="mb-25">
        <div class="sDash_form-action">
         <sdButton class="btn-signin w-100" type="primary">
          2fa deactiveren
         </sdButton>
        </div>
       </a-col>
      </a-row>
     </sdCards>
    </a-col>
   </a-row>
   <div v-if="$can('updatepermissions', 'user')" class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Autorisatie">
      <a-skeleton v-if="loadingUser" active/>
      <a-form v-if="!loadingUser" :model="form" :rules="rules2" layout="vertical" @finish="UpdateUser2">
       <a-row :gutter="30">
        <a-col :span="12" class="mb-25">
         <a-form-item label="Organisatie" name="organizationID">
          <a-select v-model:value="form.organizationID" :allowClear="true" :disabled="!$can('update', 'user')"
                    class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in userAllowedPermissions.organizations"
                            :key="index"
                            :value="item.id" name="organizationID">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :span="12" class="mb-25">
         <a-form-item label="Rol" name="roleID">
          <a-select v-model:value="form.roleID" :allowClear="true" :disabled="!$can('update', 'user')"
                    class="sDash_fullwidth-select"
                    name="roleID"
                    size="large">
           <a-select-option v-for="(item,index) in userAllowedPermissions.roles" :key="index"
                            :value="item.id">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :span="12" class="mb-25">
         <a-form-item label="Team" name="teamID">
          <a-select v-model:value="form.teamID" :allowClear="true" :disabled="!$can('update', 'user')"
                    class="sDash_fullwidth-select"
                    name="teamID"
                    size="large">
           <a-select-option v-for="(item,index) in teams" :key="index" :value="item.id">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :span="12" class="mb-25">
         <a-form-item label="Additionele organisaties" name="additionalAllowedOrganizationIDs">
          <a-select v-model:value="form.additionalAllowedOrganizationIDs" :allowClear="true"
                    :autoClearSearchValue="true"
                    :disabled="!$can('update', 'user')"
                    class="sDash_fullwidth-select" maxTagCount="responsive"
                    mode="multiple"
                    size="large"
          >
           <a-select-option v-for="(item,index) in userAllowedPermissions.organizations"
                            :key="index"
                            :value="item.id" name="additionalAllowedOrganizationIDs ">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :lg="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton :disabled="loadingPatchUser" :loading="loadingPatchUser && (clickedButton === 2)"
                    class="btn-signin" type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Product rechten">
      <a-skeleton v-if="loadingUser" active/>
      <a-form v-if="!loadingUser" :model="form" :rules="rules3" layout="vertical"
              @finish="UpdateUser3">
       <a-row :gutter="30">
        <a-col :span="24" class="mb-25">
         <a-form-item label="Producten">
          <a-tree-select
           v-model:value="form.productTree"
           :disabled="!$can('update', 'user')"
           :maxTagCount="isMobile?1:4"
           :show-checked-strategy="SHOW_PARENT"
           :style="{ width: '100%' }"
           :tree-data="productTreeConfig"
           allow-clear
           class="ant-tree-select"
           search-placeholder="Please select"
           tree-checkable
          />
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :lg="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton v-if="$can('update', 'user')" :disabled="loadingPatchUser"
                    :loading="loadingPatchUser && (clickedButton === 3)"
                    class="btn-signin" type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <sdCards>
    <a-row>
     <a-col :lg="12" :xs="24">
      <div class="sDash_form-action">
       <Button class="ant-btn ant-btn-light" size="large" type="light" @click="$router.replace('/user')">
        Terug
       </Button>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, InfoWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {Button, TreeSelect} from "ant-design-vue";
import locale from "@/static/locale/nl.json";
import countries from '@/config/staticData/countries.json';
import {getItem} from "../../utility/localStorageControl";
import {generatePassword} from "../../utility/utility";
import {getApiURL} from "../../config/dataService/dataService";
import {isMobile} from 'mobile-device-detect';

export default defineComponent({
 name: 'UserEdit',
 components: {
  BasicFormWrapper,
  Main,
  Upload,
  Button,
  InfoWrapper,
 },
 data() {
  return {
   SHOW_PARENT: TreeSelect.SHOW_PARENT,
   loading: false,
   fileUrl: getApiURL(),
  }
 },
 setup() {
  const clickedButton = ref(0);
  const showNewPasswordModal = ref(false);
  const newPassword = ref('');
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const teams = computed(() => state.team.teams);
  const loadingUser = computed(() => state.user.loadingUser);
  const productTreeConfig = computed(() => state.user.productTreeConfig);
  const userAllowedPermissions = computed(() => state.user.userAllowedPermissions);
  const form = reactive(computed(() => state.user.user));
  const errorUser = computed(() => state.user.errorUser);
  const loadingPatchUser = computed(() => state.user.loadingPatchUser);
  const rules1 = {
   firstName: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   lastName: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   email: [
    {
     required: true,
     type: "email",
     message: "Vul een valide e-mailadres in",
    },
   ],
   username: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const rules2 = {
   organizationID: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   roleID: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const rules3 = {
   allowedProductTree: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const handleUpload = async (info) => {
   if (info.file.status === 'done') {
    await dispatch('getUser', params.id);
    await dispatch('fileUploadedSuccess');
   } else if (info.file.status === 'error') {
    await dispatch('fileUploadedError');
   }
  };

  const handleChange = (type, event) => {
   if (type === 'ROLE') {
    form.value.roleID = event;
    return;
   }
   if (type === 'ORGANIZATION') {
    form.value.organizationID = event;
    return;
   }
   if (type === 'TEAM') {
    form.value.teamID = event;
    return;
   }
  };

  const UpdateUser1 = () => {
   clickedButton.value = 1;
   dispatch('patchUser', {
    id: params.id,
    firstName: form.value.firstName,
    middleName: form.value.middleName,
    lastName: form.value.lastName,
    email: form.value.email,
    username: form.value.username,
    organizationID: form.value.organizationID,
   });
  };

  const UpdateUser2 = () => {
   clickedButton.value = 2;
   dispatch('patchUser', {
    id: params.id,
    roleID: form.value.roleID,
    teamID: form.value.teamID,
    organizationID: form.value.organizationID,
    additionalAllowedOrganizationIDs: form.value.additionalAllowedOrganizationIDs,
   });
  };

  const UpdateUser3 = () => {
   clickedButton.value = 3;
   dispatch('patchUserAllowedProductTree', {
    id: params.id,
    productTree: form.value.productTree,
   });
  };

  const handlePasswordResetModalCancel = () => {
   newPassword.value = '';
   showNewPasswordModal.value = false;
  }

  const showPasswordModal = () => {
   showNewPasswordModal.value = true;
  }

  const resetPassword = () => {
   const password = generatePassword();
   newPassword.value = password
   dispatch('patchPassword', {
    value: {
     id: params.id,
     password: password,
     status: 'PENDING',
    },
    showModal: showPasswordModal,
   });
  };

  const updateUserStatus = async (status) => {
   await dispatch('patchUser', {
    id: params.id,
    status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
   });
   await dispatch('getUser', params.id);
  };

  const fileUploadHeaders = {
   Authorization: `Bearer ${getItem('access_token')}`
  };

  onMounted(async () => {
   await dispatch('getUserAllowedPermissions')
   await dispatch('getTeams', {pageSize: 100});
   await dispatch('getUser', params.id);
   await dispatch('getUserAllowedProductTree', params.id);
   await dispatch('getUserProductTreeConfig', params.id);
  });

  return {
   form,
   rules1,
   rules2,
   rules3,
   loadingUser,
   locale,
   countries,
   teams,
   errorUser,
   productTreeConfig,
   handleChange,
   UpdateUser1,
   UpdateUser2,
   UpdateUser3,
   loadingPatchUser,
   clickedButton,
   handleUpload,
   fileUploadHeaders,
   resetPassword,
   handlePasswordResetModalCancel,
   showNewPasswordModal,
   newPassword,
   updateUserStatus,
   userAllowedPermissions,
   isMobile,
  };
 },
});
</script>
